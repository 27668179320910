import React from 'react'

const Kofi = () => (
    <a href='https://ko-fi.com/J3J03D9N2' target='_blank' rel="noopener noreferrer">
        <img height='36' style={{'border': '0px', 'height': '36px'}} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com' />
    </a>
)


export default Kofi
